<template>
  <SupportPageTemplate
    bannerSrc="/assets/image/terms-and-policy_banner.jpg"
    :pageTitle="$t('label.discoverTheJoyOfCookingWithCookXAsia')"
    :pageSubtitle="$t('label.beAnExpertInTheKitchen')"
    :isPDF="true"
    topic="termsAndConditions"
  ></SupportPageTemplate>
</template>

<script>
  import SupportPageTemplate from '@/views/components/support/SupportPageTemplate';

  export default {
    name: 'TermsAndConditions',
    components: {
      SupportPageTemplate
    },
    data: () => ({
      content: null
    })
  };
</script>
